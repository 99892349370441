// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { getFontSize, getLineHeight } from '../../styles/style-helpers'

import type { ImageModuleViewModel } from '../../types/ImageModuleViewModel'
import type { AreaTypes } from '../../types/enums/AreaTypes'

import Ribbon from '../../components/Ribbon/Ribbon'
import { mediaQuery } from '../../styles/media'
import ImageFullscreen from '../../components/ImageFullscreen/ImageFullscreen'

export type Props = ImageModuleViewModel & {
  customWidthPercentage?: number,
}

const CaptionContainer = styled.div`
  width: 100%;
  font-size: ${getFontSize('small')};
  margin-top: ${rem(8)};
`

const CaptionContainerItem = styled.p`
  font-size: ${getFontSize('small')};
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 300;
  line-height: ${getLineHeight('normal')};
`

const ImageContainer = styled.div`
  width: 100%;

  ${mediaQuery.md`
  width: ${props => props.customWidthPercentage}%;
  `}
`

class ImageModule extends React.Component<Props, {}> {
  static displayName = 'ImageModule'
  static defaultProps = {}

  render() {
    const { image, caption, credit, customWidthPercentage, area } = this.props
    if (!image) return null

    return (
      <Ribbon noGrid={area === 'narrow'}>
        <ImageContainer customWidthPercentage={customWidthPercentage}>
          <ImageFullscreen {...image} />
          {caption && (
            <CaptionContainer>
              <CaptionContainerItem>{caption}</CaptionContainerItem>
              <CaptionContainerItem style={{ marginBottom: 0 }}>
                {credit}
              </CaptionContainerItem>
            </CaptionContainer>
          )}
        </ImageContainer>
      </Ribbon>
    )
  }
}

export default ImageModule
